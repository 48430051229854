import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Input from '../../atoms/Input'

const TextFieldStyled = styled(Input)`
  color: #212121 !important;
  margin-bottom: 4.5%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  .MuiInput-input {
    color: black;
    padding-bottom: 7px;
  }

  .MuiFormLabel-colorPrimary {
    color: #828487;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: left;
  }

  .MuiButton-textPrimary {
    font-size: 14px;
    padding-bottom: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.04em;
    padding: 0;
    color: black;
    text-decoration: underline;
  }
`

const InputEmail = ({
  showError = false,
  errors,
  label,
  onChange,
  ...props
}) => {
  return (
    <TextFieldStyled
      fullWidth
      error={!!errors?.email || showError}
      id="standard-adornment-email"
      variant="standard"
      type="email"
      onChange={onChange}
      label={label}
      helperText={errors.email ? errors.email.message : null}
      {...props}
    />
  )
}

InputEmail.propTypes = {
  showError: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default InputEmail
