import { createSlice } from '@reduxjs/toolkit'
import fetchProductListBySku from './fetchProductListBySku'

export const productListBySkuSlice = createSlice({
  name: 'product/sku',
  initialState: {
    status: 'idle',
    listSku: [],
    products: []
  },
  reducers: {},
  extraReducers: {
    [fetchProductListBySku.pending]: state => {
      state.status = 'loading'
    },
    [fetchProductListBySku.rejected]: state => {
      state.status = 'error'
    },
    [fetchProductListBySku.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      state.listSku = payload?.listSku
      state.products = payload?.products
    }
  }
})

const reducer = productListBySkuSlice.reducer
export default reducer
