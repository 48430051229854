import { createAsyncThunk } from '@reduxjs/toolkit'
import subOrderService from '../../../data/services/subOrderService'

const fetchStores = createAsyncThunk(
  'stores/search',
  async ({ storeName }, { signal, rejectWithValue }) => {
    const { data } = await subOrderService.storeSearch({
      storeName,
      signal
    })

    if (!data) {
      rejectWithValue(false)
    }
    return data
  }
)
export default fetchStores
