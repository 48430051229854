import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  signInWithPopup,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  setPersistence
} from 'firebase/auth'

import Button from '@mui/material/Button'

import { normalizeAuth } from '../../../../domain/adapters/validateEmail/normalizeUser'

import auth from '../../../../data/firebase/auth'
import persistentStore from '../../../../data/persistentStore'

import Card from '../../atoms/Card'
import Text from '../../atoms/Typography/Text'
import Title from '../../atoms/Typography/Title'
import ButtonSignIn from '../../atoms/ButtonSignIn'
import Profile from '../../../assets/profile.svg'
import { validateEmail } from '../../../../domain/adapters/validateEmail/validateEmail'
import { checkPermission } from '../../../../domain/adapters/validateEmail/checkPermission'
import userServices from '../../../../data/services/userServices'

const LayoutLogin = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.third.dark};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const Logo = styled('img')`
  height: 180px;
  width: 180px;
  border: 1px solid #c1c1c1;
  @media (max-width: 700px) {
    margin-bottom: 10px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
    height: 80px;
    width: 80px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 24px;
  line-height: 26px;
  font-style: light;
  font-weight: normal;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 10%;

  color: ${({ theme }) => theme.palette.third.light};
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
`
const SubTitleLogo = styled(TitleLogo)`
  font-size: 20px;
  line-height: 24px;
  margin-top: 5px;
  color: #b7b7b7;
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

const SubLayoutLogin = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const TitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`
const SubLayoutLoginRight = styled(SubLayoutLogin)`
  background-color: ${({ theme }) => theme.palette.third.light};
`
const Icon = styled('img')``

const CardLogin = styled(Card)`
  border-radius: 10px;
  max-width: 535px;
  padding-left: 64px;
  padding-right: 61px;
  padding-top: 3%;
  padding-bottom: 5%;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 10px;

  @media (max-width: 1100px) {
    width: 100%;
    max-width: 90%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
`
const Divider = styled('div')`
  margin-bottom: 20px;
`

const ButtonBase = styled(Button)`
  width: 413px;
  height: 47px;
  color: #313234;
  border: 1px solid #313234;
  box-sizing: border-box;
  padding: 0px;
  text-transform: none;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || '/'

  const googleHandler = useCallback(async () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account',
      hd: 'kitchencenter.cl'
    })

    try {
      setPersistence(auth, indexedDBLocalPersistence)
      const { user } = await signInWithPopup(auth, provider)
      const currentUser = await user.getIdTokenResult()
      if (
        validateEmail(user.email) &&
        !checkPermission(currentUser.claims?.permissions)
      ) {
        await userServices.customRole({ uid: user.uid })
      }
      const newUser = await user.getIdTokenResult(true)

      const userAuth = normalizeAuth({ ...user, ...newUser.claims })
      persistentStore.setToken(newUser?.token)
      persistentStore.setUser(userAuth)
      return navigate(from, { replace: true })
    } catch (error) {
      persistentStore.deleteAuth()
      navigate('/ingresar')
    }
  }, [navigate, from])

  return (
    <LayoutLogin>
      <SubLayoutLogin>
        <TitleContainer>
          <TitleLogo variant="h1">Tracking</TitleLogo>
          <Logo
            alt="logo"
            src="https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927"
          />
        </TitleContainer>
        <SubTitleLogo variant="h2">Seguimiento de pedidos 📦</SubTitleLogo>
      </SubLayoutLogin>
      <SubLayoutLoginRight>
        <CardLogin>
          <TitleContainer>
            <Divider>
              <Title variant="h3">Iniciar sesión</Title>{' '}
            </Divider>
          </TitleContainer>
          <Divider>
            <Text>
              Si tienes correo Kitchen Center presiona “Continuar con
              Google”, de lo contrario ingresa con tu correo y contraseña.
            </Text>
          </Divider>
          <Divider>
            <ButtonSignIn
              isGoogle
              onClick={async () => {
                await googleHandler()
              }}
            />
          </Divider>
          <ButtonBase
            startIcon={<Icon src={Profile} />}
            onClick={() => navigate('cuenta')}
          >
            Ingresar con otra cuenta
          </ButtonBase>
        </CardLogin>
      </SubLayoutLoginRight>
    </LayoutLogin>
  )
}

export default Login
