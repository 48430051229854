/* eslint-disable linebreak-style */
import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import InputEmail from '../../molecules/InputEmail/InputEmail'

const InputEmailController = ({ showError, errors, label, control }) => {
  return (
    <Controller
      control={control}
      name="email"
      render={({ field: { onChange, ref, ...fields } }) => (
        <InputEmail
          {...{ showError, errors, label }}
          onChange={onChange}
          {...fields}
          inputRef={ref}
        />
      )}
    />
  )
}

export default InputEmailController

InputEmailController.propTypes = {
  showError: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.object
}
