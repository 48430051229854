import { createAsyncThunk } from '@reduxjs/toolkit'
import normalizeProducts from './normalizeProducts'
import isEmpty from 'lodash/isEmpty'
import productAdminServices from '../../../data/services/productAdminServices'

const fetchProductListBySku = createAsyncThunk(
  'product/by-sku-list',
  async ({ listSku }, { rejectWithValue, signal }) => {
    try {
      const response = await productAdminServices.getProductBySku({
        listSku,
        signal
      })
      if (!response.ok) {
        return rejectWithValue('Error del servidor')
      }

      const data = await response.json()
      const productsBase = normalizeProducts(data.productVariants)

      if (isEmpty(productsBase)) {
        return rejectWithValue("Sin sku's")
      }

      return { products: productsBase, listSku }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchProductListBySku
