import { combineReducers } from '@reduxjs/toolkit'
import orderReducer from '../features/orders/orderSlice'

import SearchReducer from '../features/searchTrackingApi/searchSlice'
import deliveryEventReducer from '../features/deliveryEvent/deliveryEventSlice'
import orderDownloadReducer from '../features/orderListDownload/orderListDownloadSlice'
import ProductSkuReducer from '../features/productListBySku/productListBySkuSlice'
import StoreReducer from '../features/stores/storeSlice'

const reducers = combineReducers({
  search: SearchReducer,
  deliveryEvent: deliveryEventReducer,
  order: combineReducers({
    list: orderReducer,
    export: orderDownloadReducer
  }),
  products: combineReducers({
    sku: ProductSkuReducer
  }),
  store: StoreReducer
})

export default reducers
