import React from 'react'
import { number, string, oneOfType } from 'prop-types'
import Badget from '../../atoms/Badget'

export const badgetList = new Map([
  [-1, { status: 'error', title: 'Fallido', description: '' }],
  [
    0,
    {
      status: 'warning',
      title: 'Compra confirmada',
      description: 'Pedido procesado contablemente.'
    }
  ],
  [1, { status: 'warning', title: 'Confirmado', description: '' }],
  [
    2,
    {
      status: 'warning',
      title: 'Preparado',
      description:
        'Pedido está listo para ser recogido por el servicio de mensajería (esperando recogida por el courier).'
    }
  ],
  [
    3,
    {
      status: 'warning',
      title: 'Retirado por courier',
      description:
        'Pedido ha sido recogido por el servicio de mensajería y está en camino. Courier ha recogido el pedido, está en tránsito.'
    }
  ],
  [
    4,
    {
      status: 'warning',
      title: 'En tránsito',
      description:
        'Pedido está en camino 🚚 y está siendo transportado a diferentes ubicaciones antes de llegar a su destino final. '
    }
  ],
  [
    5,
    {
      status: 'warning',
      title: 'En reparto',
      description:
        'Pedido está en la etapa final de entrega y pronto llegará a su domicilio. Pedido en última milla, el courier está realizando la entrega. Intento de entrega fallido, requiere seguimiento con el courier para reintentar.'
    }
  ],
  [
    6,
    {
      status: 'error',
      title: 'Intento de entrega',
      description:
        'Se intentó entregar Pedido, pero no se pudo completar la entrega. Por favor, verifique si hay instrucciones adicionales o contacte al servicio de mensajería. '
    }
  ],
  [
    7,
    {
      status: 'success',
      title: 'Entregado',
      description: 'Pedido ha sido entregado exitosamente 📦🎉.'
    }
  ],
  [
    8,
    {
      status: 'pending',
      title: 'Información no disponible',
      description:
        'No pudimos obtener la información de seguimiento del pedido en este momento. Por favor, verifique el número de seguimiento, reintente más adelante la busqueda o contáctenos para más detalles.'
    }
  ],
  [
    9,
    {
      status: 'pending',
      title: 'Pendiente confirmación pago',
      description: ''
    }
  ],
  [
    10,
    {
      status: 'store',
      title: 'Retiro en tienda',
      description:
        'Producto(s) que deben ser retirados por el cliente en nuestra tienda fisíca.'
    }
  ],
  [
    11,
    {
      status: 'shipping',
      title: 'Envío desde tienda',
      description: ''
    }
  ],
  [
    12,
    {
      status: 'shipping',
      title: 'Despacho a domicilio',
      description: 'Productos enviados al domicilio del cliente.'
    }
  ],
  [null, { status: 'error', title: 'Fallido', description: '' }],
  [NaN, { status: 'error', title: 'Fallido', description: '' }]
])

const BadgetStatus = ({ status, size }) => {
  const props = badgetList.get(Number(status))
  return <Badget size={size} {...props} />
}
BadgetStatus.propTypes = {
  status: oneOfType([number, string]),
  size: string
}
BadgetStatus.defaultProps = { status: 8 }
export default BadgetStatus
