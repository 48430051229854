/* eslint-disable linebreak-style */
import * as yup from 'yup'

export const schema = yup
  .object({
    email: yup
      .string()
      .email('El email ingresado no es válido')
      .max(255)
      .required('Campo requerido'),
    password: yup.string().required('Campo requerido')
  })
  .required()
