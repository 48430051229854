import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const TextEmpty = styled(Typography)`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 0.15px;
  color: #cbcbcb;
`
TextEmpty.displayName = 'TextEmpty'
export default TextEmpty
