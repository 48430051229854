/* eslint-disable no-console */
import { useEffect, useState, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

const useInstallApp = () => {
  const promptEvent = window.$promptEvent || window.deferredPrompt
  const [isSupportPWA, setSupportPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(promptEvent)

  const handlerPrompt = useCallback(e => {
    e.preventDefault()
    console.info('Captura de A2HS 🧐')
    setSupportPWA(true)
    setPromptInstall(e)
  }, [])

  useEffect(() => {
    // https://web.dev/customize-install/#beforeinstallprompt
    window.addEventListener('beforeinstallprompt', handlerPrompt)
    // https://web.dev/customize-install/#detect-install
    window.addEventListener('appinstalled', e => {
      e.preventDefault()
      console.info('El usuario instaló el aviso de A2HS 🤩')
      setSupportPWA(false)
    })

    if (!isEmpty(promptInstall)) {
      setSupportPWA(true)
    }

    return () => window.removeEventListener('transitionend', handlerPrompt)
  }, [promptInstall, handlerPrompt])

  const onPrompt = useCallback(
    e => {
      e.preventDefault()

      if (!promptInstall) {
        return null
      }

      promptInstall.prompt()
      promptInstall.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.info('El usuario aceptó el aviso de A2HS 🥳')
          setSupportPWA(false)
        } else {
          console.info('El usuario descartó la solicitud de A2HS 😕')
          setSupportPWA(true)
        }
      })
    },
    [promptInstall]
  )

  return [isSupportPWA, onPrompt]
}

export default useInstallApp
