import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'

import Card from '../../atoms/Card'
import Text from '../../atoms/Typography/Text'
import Title from '../../atoms/Typography/Title'

import LoginForm from '../../organisms/LoginForm/LoginForm'

const LayoutLogin = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.third.dark};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const Logo = styled('img')`
  height: 180px;
  width: 180px;
  border: 1px solid #c1c1c1;
  @media (max-width: 700px) {
    margin-bottom: 10px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
    height: 80px;
    width: 80px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 24px;
  line-height: 26px;
  font-style: light;
  font-weight: normal;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 10%;

  color: ${({ theme }) => theme.palette.third.light};
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
`
const SubTitleLogo = styled(TitleLogo)`
  font-size: 20px;
  line-height: 24px;
  margin-top: 5px;
  color: #b7b7b7;
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

const SubLayoutLogin = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CardLogin = styled(Card)`
  border-radius: 10px;
  max-width: 535px;
  @media (max-width: 1100px) {
    width: 90%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  padding-left: 64px;
  padding-right: 61px;
  padding-top: 3%;
  padding-bottom: 5%;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
`
const TitleContainer = styled('div')`
  margin-bottom: 20px;
`
const TitleContainerCenter = styled('div')`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const GoBackButton = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  margin-top: 2%;
  color: #5c5f62;
  text-transform: none;
`
const SubLayoutLoginRight = styled(SubLayoutLogin)`
  background-color: ${({ theme }) => theme.palette.third.light};
`
const LoginOtherAccount = () => {
  const navigate = useNavigate()
  return (
    <LayoutLogin>
      <SubLayoutLogin>
        <TitleContainer>
          <TitleLogo variant="h1">Tracking</TitleLogo>
          <Logo
            alt="logo"
            src="https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927"
          />
        </TitleContainer>
        <SubTitleLogo variant="h2">Seguimiento de pedidos 📦</SubTitleLogo>
      </SubLayoutLogin>
      <SubLayoutLoginRight>
        <CardLogin>
          <TitleContainerCenter>
            <Title variant="h3">Iniciar sesión</Title>
          </TitleContainerCenter>
          <TitleContainer>
            <Text>
              Si tu cuenta no es de Kitchen Center, ingresa con tu correo y
              contraseña.
            </Text>
          </TitleContainer>
          <TitleContainer>
            <LoginForm />
          </TitleContainer>
        </CardLogin>
        <GoBackButton onClick={() => navigate('/ingresar')}>
          Volver atrás
        </GoBackButton>
      </SubLayoutLoginRight>
    </LayoutLogin>
  )
}

export default LoginOtherAccount
