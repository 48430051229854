import React from 'react'
import PropTypes from 'prop-types'
import ModalMUI from '@mui/material/Modal'
import styled from 'styled-components'
import Text from '../../atoms/Typography/Text'
import Button from '@mui/material/Button'

const ModalStyled = styled(ModalMUI)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const Container = styled('div')`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 544px;
  height: 371px;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 44px;
`

const MainText = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #212121;
  margin-bottom: 5%;
  font-weight: 400;
`

const HelperText = styled(Text)`
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #767676;
  margin-bottom: 8%;
  font-weight: 400;
`

const StyledButton = styled(Button)`
  border-radius: 2.11111px;
  width: 100%;
`

const Modal = ({ open, handleClose }) => {
  return (
    <ModalStyled open={open} onClose={handleClose}>
      <Container>
        <Title>Solicitud enviada</Title>
        <MainText>
          Hemos recibido tu solicitud de acceso y está siendo procesada. Te
          notificaremos cuando tu usuario esté habilitado.
        </MainText>
        <HelperText>
          En caso de ya estar registrado, verifica que el correo o clave
          ingresados sean correctos.
        </HelperText>
        <StyledButton
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Aceptar
        </StyledButton>
      </Container>
    </ModalStyled>
  )
}
Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
}
export default Modal
