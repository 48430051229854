import { jwtDecode } from 'jwt-decode'

/**
 * Decodifica un token JWT utilizando el certificado proporcionado.
 *
 * @param {string} cert - El certificado utilizado para verificar y decodificar el token JWT.
 * @returns {function(string): Object} - Una función que toma el token JWT como argumento y devuelve los datos decodificados.
 */
export const decodeJwt = cert => data => {
  const decoded = jwtDecode(data, cert, { algorithms: ['RS512'] })
  return decoded
}
