import { initializeApp } from 'firebase/app'
import {
  getAuth,
  setPersistence,
  indexedDBLocalPersistence
} from 'firebase/auth'
import firebaseConfig from './firebase.config'

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
auth.languageCode = 'es'
setPersistence(auth, indexedDBLocalPersistence)
export default auth
