import { AES, enc } from 'crypto-js'
import Base64 from 'crypto-js/enc-base64'
/**
 * Local storage handler for SSR (Server Side Rendering).
 * Uses window.localStorage if available, otherwise provides mock functions.
 */
const localStorage =
  typeof window === 'undefined'
    ? {
        getItem: () => undefined,
        setItem: () => undefined,
        removeItem: () => undefined
      }
    : window.localStorage

/**
 * Secret key used for encryption and decryption.
 * @constant {string}
 */
const SECRET_KEY =
  'tracking-D$;#B%))iSJuKoVQYQC1L4pJNF9Wz_%(][n=9g6s5ZtyL8tf}~'

/**
 * Keys used for storing data in local storage.
 * @constant {Object}
 * @property {string} MODETHEME - Key for storing theme mode.
 * @property {string} USER - Key for storing user data.
 * @property {string} TOKEN - Key for storing token.
 */
const keyList = {
  MODETHEME: '@theme-light-or-dark',
  USER: '@user-tracking',
  TOKEN: '@token'
}

/**
 * Encrypts data using AES encryption.
 * @param {any} data - The data to be encrypted.
 * @returns {string} - The encrypted data.
 */
const encryptData = data =>
  AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()

/**
 * Decrypts data using AES decryption.
 * @param {string} data - The encrypted data to be decrypted.
 * @returns {any} - The decrypted data, or undefined if decryption fails.
 */
const decryptData = data =>
  JSON.parse(AES.decrypt(data, SECRET_KEY).toString(enc.Utf8))

/**
 * Encodes data to base64 using crypto-js.
 * @param {any} data - The data to be encoded.
 * @returns {string} - The base64 encoded data.
 */
const encodeBase64 = data =>
  Base64.stringify(enc.Utf8.parse(JSON.stringify(data)))

/**
 * Decodes base64 data using crypto-js.
 * @param {string} data - The base64 encoded data to be decoded.
 * @returns {any} - The decoded data.
 */
const decodeBase64 = data =>
  JSON.parse(enc.Utf8.stringify(Base64.parse(data)))

const getItem = key => {
  const item = localStorage.getItem(key)
  if (item === 'undefined') {
    return undefined
  }

  try {
    if (key === keyList.TOKEN) {
      return decodeBase64(item)
    }
    return decryptData(item)
  } catch (cause) {
    return item
  }
}

const setItem = (key, value) => {
  const item =
    key === keyList.TOKEN ? encodeBase64(value) : encryptData(value)
  localStorage.setItem(key, item)
}

const removeItem = key => localStorage.removeItem(key)

const PersistentStore = () => ({
  getTheme: () => getItem(keyList.MODETHEME),

  setTheme: theme => setItem(keyList.MODETHEME, theme),

  getUser: () => getItem(keyList.USER),

  setUser: user => setItem(keyList.USER, user),

  deleteUser: () => removeItem(keyList.USER),

  getToken: () => getItem(keyList.TOKEN),

  setToken: token => setItem(keyList.TOKEN, token),

  deleteToken: () => removeItem(keyList.TOKEN),

  deleteAuth: () => {
    localStorage.clear()
  }
})

export default PersistentStore()
