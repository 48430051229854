import styled from 'styled-components'
import Text from './Text'

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-align: left;
`

export default Title
