import React from 'react'
import { bool } from 'prop-types'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import icon from '../../../assets/icon/microsfot.jpg'
import google from '../../../assets/icon/google.svg'

const ButtonBase = styled(Button)`
  width: 413px;
  height: 47px;
  color: #313234;
  border: 1px solid #313234;
  box-sizing: border-box;
  text-transform: none;
  padding: 0px;
  padding-right: 20px;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const Icon = styled('img')``

const ButtonSignIn = ({ isGoogle, ...props }) => {
  return (
    <ButtonBase
      startIcon={isGoogle ? <Icon src={google} /> : <Icon src={icon} />}
      {...props}
    >
      {isGoogle ? 'Continuar con google' : 'Continuar con Microsoft'}
    </ButtonBase>
  )
}
ButtonSignIn.propTypes = {
  isGoogle: bool
}
ButtonSignIn.defaultProps = {
  isGoogle: false
}
export default ButtonSignIn
