import pipe from 'lodash/fp/pipe'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'

export const deleteUndefinedEntries = object =>
  pipe(
    Object.entries,
    o => o.filter(([, value]) => value !== undefined && !isEmpty(value)),
    fromPairs
  )(object)
