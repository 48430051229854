import { createAsyncThunk } from '@reduxjs/toolkit'
import deliveryEventServices from '../../../data/services/deliveryEventServices'
import normalizeDeliveryEvent from './normalizeDeliveryEvent'

const fetchDeliveryEvent = createAsyncThunk(
  'trackingId',
  async ({ trackingId }, { rejectWithValue }) => {
    try {
      const response = await deliveryEventServices.getByTrackingId({
        trackingId
      })
      if (!response.ok) {
        return rejectWithValue('Error del servidor')
      }
      const { data } = response

      const normalize = data.map(normalizeDeliveryEvent)

      return normalize
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchDeliveryEvent
