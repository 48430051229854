import { fetchDecodedTracking } from '../client/fetchDecoded'
import persistentStore from '../persistentStore'
import methods from './httpMethods'

const deliveryEventServices =
  (baseUrl, endpoint) =>
  ({ get }) => ({
    getByTrackingId: async ({ trackingId }) => {
      return await fetchDecodedTracking(
        `${baseUrl}${endpoint}/filter?tracking_number=${trackingId}`,
        {
          method: get,
          headers: {
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME
          }
        }
      )
    }
  })

export default deliveryEventServices(
  process.env.REACT_APP_API_PATH,
  '/v1/events'
)(methods)
