/* eslint-disable react/react-in-jsx-scope */
import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { signOut } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import isNil from 'lodash/isNil'

import auth from '../../../../data/firebase/auth'
import persistentStore from '../../../../data/persistentStore'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import useMediaQuery from '@mui/material/useMediaQuery'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'

import Dropdown from '../../atoms/Dropdown/Dropdown'
import Text from '../../atoms/Typography/Text'
import Navigate from '../../atoms/Navigate'

const NaviagetMenuItem = styled(Navigate)`
  text-decoration: none;
  text-decoration-line: none;
  margin: 0px;
  padding: 0px;
`

const ButtonUser = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 180px;
  height: 52px;
  padding-left: 10px;
  padding-right: 5px;
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.info.main};
  transition: background-color 0.4s;
  &:hover {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${({ theme }) =>
      theme.palette.type !== 'dark' ? '#202223' : '#313234'};
  }

  @media (max-width: 700px) {
    width: auto;
  }
`

const UserDropdown = styled(Dropdown)`
  margin-top: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-color: ${({ theme }) => theme.palette.info.dark};
  @media (max-width: 700px) {
    margin-top: 8px;
  }
`

const UserMenuItem = styled(MenuItem)`
  height: 35px;
  width: 175px;
  color: ${({ theme }) => theme.palette.info.dark};
`

const TextMenuItem = styled(Text)`
  font-size: 12px;
  line-height: 14px;
  margin-left: 7px;
`

const TextUser = styled(Text)`
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  @media (max-width: 700px) {
    font-size: 13px;
  }
`

const TextRole = styled(Text)`
  font-size: 12px;
  line-height: 14px;
  text-overflow: ellipsis;
  @media (max-width: 700px) {
    font-size: 11px;
    line-height: 13px;
  }
`

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const navigate = useNavigate()

  const logout = useCallback(async () => {
    persistentStore.deleteUser()
    persistentStore.deleteToken()
    await signOut(auth).finally(() => navigate('/ingresar'))
  }, [navigate])
  const userStore = persistentStore.getUser()
  const user = auth?.currentUser
  const location = useLocation()

  const match = useMediaQuery('(min-width:700px)')
  return (
    <>
      <ButtonUser
        onClick={handleClick}
        data-testid="UserMenu__ButtonUser"
        type="button"
      >
        {!isNil(user) || userStore?.picture ? (
          <Avatar
            src={userStore?.picture || user?.photoURL}
            alt="photoUrl"
          />
        ) : (
          <Skeleton variant="circular" width={40} height={40} />
        )}

        {match && (
          <Box ml="10px" mr="10px" minWidth="100px">
            {!isNil(user) || userStore?.displayName ? (
              <TextUser>
                {userStore?.displayName || user?.displayName}
              </TextUser>
            ) : (
              <Skeleton variant="text" />
            )}
            {!isNil(user) ? (
              <TextRole>
                {userStore?.email?.split('@')[1] ||
                  user?.email?.split('@')[1]}
              </TextRole>
            ) : (
              <Skeleton variant="text" />
            )}
          </Box>
        )}
      </ButtonUser>
      <UserDropdown
        id="logout"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!match && (
          <UserMenuItem disabled>
            <Box>
              <TextUser>{user && user?.displayName}</TextUser>
              <TextRole>{user && user?.email?.split('@')[1]}</TextRole>
            </Box>
          </UserMenuItem>
        )}
        <NaviagetMenuItem to="/">
          <UserMenuItem
            onClick={handleClose}
            selected={location.pathname === '/'}
          >
            <LocalShippingOutlinedIcon fontSize="small" />
            <TextMenuItem>Ver tracking</TextMenuItem>
          </UserMenuItem>
        </NaviagetMenuItem>
        <NaviagetMenuItem to="/pedidos">
          <UserMenuItem
            onClick={handleClose}
            selected={location.pathname === '/pedidos'}
          >
            <Inventory2OutlinedIcon fontSize="small" />
            <TextMenuItem>Ver pedidos</TextMenuItem>
          </UserMenuItem>
        </NaviagetMenuItem>
        <UserMenuItem onClick={logout}>
          <LogoutOutlinedIcon fontSize="small" />
          <TextMenuItem>Cerrar Sesión</TextMenuItem>
        </UserMenuItem>
      </UserDropdown>
    </>
  )
}

export default UserMenu
