import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchSearch from './fetchSearch'

export const searchAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id),
  sortComparer: (a, b) => b.orderCreatedAt.localeCompare(a.orderCreatedAt)
})

export const searchSlice = createSlice({
  name: 'search',
  initialState: searchAdapter.getInitialState({
    status: 'idle',
    query: '',
    entities: {}
  }),
  reducers: {
    addQuery(state, action) {
      state.query = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchSearch.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchSearch.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchSearch.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      searchAdapter.setAll(state, payload)
    })
  }
})
export const { addQuery } = searchSlice.actions
const reducer = searchSlice.reducer
export default reducer
