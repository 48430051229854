import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getSearch,
  resultsSearch
} from '../../../../domain/features/searchTrackingApi/getSearch'
import BadgetStatus from '../../molecules/BadgetStatus'
import TableTemplate from '../TableTemplate/TableTemplate'

const TableTemplateTracking = props => {
  const columns = useMemo(
    () => [
      {
        Header: 'Pedido',
        accessor: 'orderId'
      },
      {
        Header: 'Datos cliente',
        accessor: 'clientName'
      },
      {
        Header: 'Fecha compra',
        accessor: 'dateOfPurchase'
      },
      {
        Header: 'Entrega comprometida',
        accessor: 'engagedDay'
      },
      {
        Header: 'Estado despacho',
        accessor: 'conditionOffice',
        Cell: cell => <BadgetStatus status={cell.value} />
      },
      {
        Header: 'Método entrega',
        accessor: 'shippingType'
      },
      {
        Header: 'Empresa transporte',
        accessor: 'transportCompany'
      },
      {
        Header: 'Cantidad de bultos',
        accessor: 'numbeOfpackages'
      },
      {
        Header: 'Canal de venta',
        accessor: 'salesChannel'
      },
      {
        Header: 'Seller',
        accessor: 'seller'
      },
      {
        Header: 'Región de destino',
        accessor: 'destination'
      }
    ],
    []
  )
  const { query } = useSelector(getSearch)
  const rows = useSelector(resultsSearch)
  return (
    <TableTemplate
      data={rows}
      columns={columns}
      query={query}
      {...props}
    />
  )
}

export default TableTemplateTracking
