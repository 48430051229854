import { fetchDecodeOrder } from '../client/fetchDecoded'
import persistentStore from '../persistentStore'
import methods from './httpMethods'

const searchService =
  (baseUrl, endpoint) =>
  ({ get }) => ({
    search: async ({ search, signal }) => {
      return await fetchDecodeOrder(
        `${baseUrl}${endpoint}?search=${encodeURIComponent(search)}`,
        {
          method: get,
          headers: {
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME
          },
          signal
        }
      )
    }
  })

export default searchService(
  process.env.REACT_APP_API_ORDER_PATH,
  '/orders'
)(methods)
