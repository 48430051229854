import React from 'react'
import { node, string, bool } from 'prop-types'

import TooltipMUI from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'

const Tooltip = ({ title, children, disabled, ...props }) => {
  return disabled || !title ? (
    children
  ) : (
    <TooltipMUI
      TransitionComponent={Zoom}
      disabled={disabled}
      title={title}
      {...props}
    >
      {children}
    </TooltipMUI>
  )
}

Tooltip.propTypes = {
  children: node,
  title: string,
  disabled: bool
}
Tooltip.defaultProps = {
  children: undefined,
  disabled: false,
  title: undefined
}

Tooltip.displayName = 'Tooltip'
export default Tooltip
