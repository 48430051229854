import persistentStore from '../../data/persistentStore'
import useInterval from './useInterval'
import auth from '../../data/firebase/auth'

const useRefreshToken = () => {
  const tokenDuration = process.env.REACT_APP_REFRESH_TOKEN
    ? parseInt(process.env.REACT_APP_REFRESH_TOKEN, 10)
    : 600000 // 10 minutos en milisegundos

  useInterval(async () => {
    const token = await auth.currentUser.getIdToken(true)
    persistentStore.setToken(token)
  }, tokenDuration)
}

export default useRefreshToken
