import { createAsyncThunk } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import createTableDeliveryList from './createTableDeliveryList'
import searchServices from '../../../data/services/searchServices'

const fetchSearch = createAsyncThunk(
  'search',
  async ({ search }, { rejectWithValue, signal }) => {
    try {
      const data = await searchServices.search({ search, signal })
      if (!data?.length) {
        return rejectWithValue('Sin datos')
      }
      const table = createTableDeliveryList(data)
      return table
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    condition: ({ search }, { getState }) => {
      const prevSearch = getState().search.query

      if (search !== prevSearch || isEmpty(search)) {
        return false
      }
    }
  }
)
export default fetchSearch
