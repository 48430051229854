import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import isNil from 'lodash/isNil'

import * as serviceWorker from '../../serviceWorkerRegistration'

const useServiceWorker = () => {
  const [registerWorker, setRegisterWorker] = useState(null)
  const [isUpdateWorker, setUpdateWorker] = useState(false)
  const [isSuccessWorker, setSuccessWorker] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState(null)

  const onSWUpdate = useCallback(async registration => {
    setUpdateWorker(true)
    setWaitingWorker(registration.waiting)
    setRegisterWorker(registration)
    toast.success('Actualizando aplicación', {
      position: 'bottom-right',
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light'
    })
  }, [])

  const onSWSuccess = useCallback(
    registration => {
      setSuccessWorker(true)
      setRegisterWorker(registration)
    },
    [setRegisterWorker]
  )

  useEffect(() => {
    const register = serviceWorker.register({
      onUpdate: onSWUpdate,
      onSuccess: onSWSuccess
    })
    return register
  }, [onSWSuccess, onSWUpdate])

  const reloadPage = useCallback(async () => {
    setUpdateWorker(true)
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    // lear more: https://github.com/facebook/create-react-app/issues/5316#issuecomment-591075209
    if (waitingWorker && registerWorker) {
      await registerWorker.unregister()
      // Makes Workbox call skipWaiting()
      waitingWorker.postMessage({
        type: 'SKIP_WAITING'
      })
      return window.location.reload()
    }

    if (isNil(waitingWorker) && registerWorker) {
      registerWorker?.registrationWorker?.update()
      return window.location.reload()
    }
  }, [registerWorker, waitingWorker])

  return {
    isUpdateWorker,
    isSuccessWorker,
    reloadPage
  }
}

export default useServiceWorker
