import React, { useState, useEffect, useCallback } from 'react'
import { useSpring, config } from '@react-spring/web'
import { MessageBox } from './styles'
import PropTypes from 'prop-types'

const messages = [
  {
    text: 'Validando su sesión por seguridad',
    readingTime: 2500,
    emoji: '🔒'
  },
  { text: 'Cargando configuración', readingTime: 2000, emoji: '⚙️' },
  { text: 'Verificando integridad', readingTime: 1500, emoji: '🔍' },
  { text: 'Preparando contenido', readingTime: 1000, emoji: '📦' },
  { text: '🚚 Casi listo', readingTime: 3000, emoji: '✅' }
]

const MessageQueue = ({ onEmojiChange }) => {
  const [index, setIndex] = useState(0)
  const nextEmoji = useCallback(
    () => setIndex(prevIndex => (prevIndex + 1) % messages?.length),
    []
  )

  const { opacity, transform } = useSpring({
    opacity: 1,
    transform: 'translate(0%)',
    from: { opacity: 0.5, transform: 'translateY(-15%)' },
    reset: true,
    loop: true,
    onRest: nextEmoji,
    config: {
      ...config.wobbly,
      duration: messages[index]?.readingTime
    }
  })

  const updateEmoji = useCallback(() => {
    onEmojiChange(messages[index]?.emoji)
  }, [index, onEmojiChange])

  useEffect(() => {
    updateEmoji()
  }, [index, updateEmoji])

  useEffect(() => {
    let isMounted = true

    const originalNextEmoji = nextEmoji

    const safeNextEmoji = () => {
      if (isMounted) {
        originalNextEmoji()
      }
    }

    return () => {
      safeNextEmoji()
      isMounted = false
    }
  }, [nextEmoji])

  return (
    <MessageBox style={{ opacity, transform }}>
      {messages[index]?.text}...
    </MessageBox>
  )
}

MessageQueue.propTypes = {
  onEmojiChange: PropTypes.func.isRequired
}

export default MessageQueue
