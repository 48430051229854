import React from 'react'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CustomButton = styled(Button)`
  background: #2f71d1;
  border: 1px solid #2f71d1;
  border-radius: 5px;
  color: white;
  padding: 5px 24px;
  text-transform: none;
  :hover {
    background: #2f71d1;
    opacity: 0.7;
  }
`

const ButtonTable = ({ label, ...props }) => {
  return <CustomButton {...props}>{label}</CustomButton>
}

ButtonTable.propTypes = {
  label: PropTypes.string
}
export default ButtonTable
