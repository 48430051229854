import React from 'react'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Back = styled(Button)`
  border: 1px solid #c1c1c1;
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 1rem;
  min-width: 40px;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  color: #313234;

  @media (max-width: 1100px) {
    margin-right: 0.5rem;
  }
  @media (max-width: 700px) {
    min-width: 35px;
    height: 35px;
    width: 35px;
  }
  &:hover {
    background-color: #e9f4fd;
  }
`

const ButtonBack = props => {
  const navigate = useNavigate()
  return (
    <Back
      onClick={() => navigate(-1)}
      data-testid="ButtonBack"
      focusRipple={true}
      {...props}
    >
      <ArrowBack />
    </Back>
  )
}

export default ButtonBack
