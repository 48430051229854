import React from 'react'
import { node, number } from 'prop-types'
import Cell from '../Cell/Cell'

const CellTooltip = ({ index, children }) => (
  <Cell align={index === 0 ? 'center' : 'inherit'}>{children}</Cell>
)

CellTooltip.propTypes = { children: node, index: number }
export default CellTooltip
