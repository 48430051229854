import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import Center from '../../../atoms/Center/Center'
import WarningAmberIcon from '../../../../assets/icon/warning.svg'

const Container = styled(Center)`
  max-width: 500px;
`
const IconWarning = styled('img')``

const TextError = styled('p')`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #8d9195;
`

const ErrorSearch = ({ searchText }) => {
  return (
    <Center data-testid="ErrorSerach__Container--center">
      <Container>
        <IconWarning src={WarningAmberIcon} alt="warning" />
        <TextError>
          No hay resultados para “{searchText}”. Intenta con otra búsqueda.
        </TextError>
      </Container>
    </Center>
  )
}
ErrorSearch.propTypes = { searchText: string.isRequired }
export default ErrorSearch
