import * as React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const ArrowContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4px;
`
const Up = styled(ArrowDropUpIcon)`
  font-size: 1.2rem;
  fill: ${({ sort }) => (sort === 'true' ? '#8d9195' : '#313234')};
  margin-bottom: -12px;
`
const Down = styled(ArrowDropDownIcon)`
  font-size: 1.2rem;
  fill: ${({ sort }) => (sort === 'false' ? '#8d9195' : '#313234')};
`

const ArrowSort = ({ sort }) => (
  <ArrowContainer>
    <Up sort={sort} />
    <Down sort={sort} />
  </ArrowContainer>
)
ArrowSort.propTypes = { sort: string }
ArrowSort.defaultProps = { sort: 'false' }
export default ArrowSort
