import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../auth/AuthContext'
import persistentStore from '../../data/persistentStore'

const ProtectedRoute = () => {
  const { currentUser } = useAuth()
  const location = useLocation()

  if (!currentUser) {
    persistentStore.deleteAuth()
    return (
      <Navigate
        to="/ingresar"
        state={{ ...location.state, from: location }}
        replace
      />
    )
  }

  return <Outlet />
}

export default ProtectedRoute
