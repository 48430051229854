import React from 'react'
import { bool, func } from 'prop-types'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Input from '../../atoms/Input'

const height = '42px'
const Search = styled(Input)`
  padding-left: 10px;
  .MuiOutlinedInput-root {
    padding: 0;
    transition: all 0.3s ease;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.info.dark};
    background-color: ${({ theme }) => theme.palette.info.main};
  }
  .MuiInputBase-input {
    padding-right: 12px;
    padding-left: 19px;
    padding-bottom: 0px;
    padding-top: 0px;
    height: ${height};
    width: 100%;
    @media (max-width: 1100px) {
      max-width: 100%;
    }
  }
  /* Ocultar íconos predeterminados de búsqueda excepto en Safari */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input[type='search']::-moz-search-decoration,
  input[type='search']::-moz-search-cancel-button {
    display: none;
  }

  input[type='search']::-ms-clear {
    display: none; /* Ocultar el botón de limpiar en IE11 */
  }

  /* Mostrar el ícono de cancelación personalizado */
  input[type='search']::-webkit-search-cancel-button {
    cursor: pointer;
    font-size: 1.2em;
    height: 10px;
    width: 10px;
    background-size: 10px 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBbZBBCsIwEEVnpl5NVETsNUS6qNB1pmuhIqV4DbuQUtzYE3ii/pgIhRDyIWFC3vz5DDf37ksiz0txqimhpu0MQLnMQG8BbdqHSUEWpO702XscPuvNjplIt/sDjcNrCiHnVlflWXnpvt5aFRHDIkoECiH/z+GoBfZ1CHlJCGYrsan6/46DeydrMcWZORU8yuzXplkK8gq2YdjdAtg8hhZVZeEMUGPG8QcpyXjHSYAGSAAAAABJRU5ErkJggg==');
    -webkit-appearance: none;
    appearance: none;
  }

  /* Estilos adicionales para el input */
  input[type='search'] {
    position: relative;
    width: 100%; /* Ocupa todo el ancho disponible */
    box-sizing: border-box; /* Incluye padding y borde en el cálculo del ancho */
    padding-right: 30px; /* Espacio para el botón personalizado */
  }

  /* Custom clear button */
  input[type='search']::after {
    content: '';
    cursor: pointer;
    font-size: 1.2em;
    height: 10px;
    width: 10px;
    background-size: 10px 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBbZBBCsIwEEVnpl5NVETsNUS6qNB1pmuhIqV4DbuQUtzYE3ii/pgIhRDyIWFC3vz5DDf37ksiz0txqimhpu0MQLnMQG8BbdqHSUEWpO702XscPuvNjplIt/sDjcNrCiHnVlflWXnpvt5aFRHDIkoECiH/z+GoBfZ1CHlJCGYrsan6/46DeydrMcWZORU8yuzXplkK8gq2YdjdAtg8hhZVZeEMUGPG8QcpyXjHSYAGSAAAAABJRU5ErkJggg==');
    position: absolute;
    right: 2px; /* Ajusta según sea necesario */
    top: 50%;
    transform: translateY(-50%);
  }
`

const IconButton = styled(Button)`
  height: ${height};
  border-left: 1px solid #c1c1c1;
  padding: 0px;
  margin: 0px;
  border-radius: 0;
  min-width: 48px;
  ${Search}:hover & {
    transition: border-left-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-left-color: #313234;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 0.3s;
  }
`

const SearchIconStyled = styled(SearchIcon)`
  transition: all 0.3s ease;
  fill: ${({ theme }) => theme.palette.info.dark};
  ${IconButton}:hover & {
    fill: ${({ theme }) => theme.palette.info.light};
  }
`

const SearchInput = ({ isloading, onClick, ...props }) => (
  <Search
    {...props}
    fullWidth
    type="search"
    id="q"
    placeholder="Buscar"
    aria-label="buscar un envío, boleta, id shopify, factura, nota de venta solicitu de tralsado, nombre, RUT o mail de un cliente"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end" style={{ margin: 0 }}>
          <IconButton
            data-testid="SearchInput__IconButton"
            onClick={onClick}
            type="submit"
            color="secondary"
          >
            {isloading ? (
              <CircularProgress
                size="1.2rem"
                color="secondary"
                data-testid="SearchInput__CircularProgress--loading"
              />
            ) : (
              <SearchIconStyled color="secondary" />
            )}
          </IconButton>
        </InputAdornment>
      )
    }}
    onKeyDown={event => {
      if (event.key === 'Enter') {
        onClick()
      }
    }}
  />
)
SearchInput.propTypes = {
  isloading: bool,
  onClick: func
}

export default SearchInput
