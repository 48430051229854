import React, { Suspense, lazy } from 'react'
import { Routes as AppRoutes, Route } from 'react-router-dom'

import Layout from '../layouts/Layout'
import Home from '../components/pages/Home'
import Login from '../components/pages/Login'
import ProtectedRoute from './ProtectedRoute'
import LoginOtherAccount from '../components/pages/Login/LoginOtherAccount'
import useRefreshToken from '../hooks/useRefreshToken'

const Tracking = lazy(() => import('../components/pages/Tracking'))
const Orders = lazy(() => import('../components/pages/Download/Download'))
const NotFound = lazy(() => import('../components/pages/NotFound'))

const Routes = () => {
  useRefreshToken()
  return (
    <AppRoutes>
      <Route path="/ingresar" element={<Login />} />
      <Route path="/ingresar/cuenta" element={<LoginOtherAccount />} />
      <Route element={<Layout />}>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route
            path=":trackingId"
            element={
              <Suspense fallback={<span>⌛ Cargando Despacho...</span>}>
                <Tracking />
              </Suspense>
            }
          />
          <Route
            path="/pedidos"
            element={
              <Suspense fallback={<span>⌛ Cargando Pedidos...</span>}>
                <Orders />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense
            fallback={<span>⌛ Cargando pagina no econtrada...</span>}
          >
            <NotFound />
          </Suspense>
        }
      />
    </AppRoutes>
  )
}

export default Routes
