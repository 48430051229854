import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Navbar from '../components/organisms/Navbar'
import background from '../assets/background.png'
import ButtonTop from '../components/atoms/ButtonTop'

const Background = styled('div')`
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
  width: 100%;
  min-height: 93vh;
`

const Margin = styled('div')`
  padding-top: 19px;
  margin-left: 60px;
  margin-right: 60px;
  @media (max-width: 1100px) {
    padding-top: 2%;
    margin-right: 2%;
    margin-left: 2%;
  }
`

const Layout = () => {
  return (
    <>
      <Navbar />
      <Background>
        <Margin>
          <Outlet />
        </Margin>
      </Background>
      <ButtonTop />
    </>
  )
}

export default Layout
