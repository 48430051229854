import React from 'react'
import UpdateIcon from '@mui/icons-material/Update'
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

import Tooltip from '../components/atoms/ToolTip/ToolTip'
import useWorker from './useWorker'

const UpdateApp = () => {
  const { isUpdateWorker, reloadPage } = useWorker()
  const match = useMediaQuery('(min-width:1100px)')
  return (
    isUpdateWorker && (
      <Tooltip
        role="tooltip"
        title="Exiten nuevas actualizaciones en Tracking de Kitchen Center, actualizá y disfruta de nuevas funcionalidades 🎉 ."
        placement="bottom-start"
        followCursor
      >
        <IconButton
          color="info"
          onClick={reloadPage}
          title="Exiten nuevas actualizaciones en Tracking de Kitchen Center, actualizá y disfruta de nuevas funcionalidades 🎉 ."
        >
          {match ? <UpdateIcon /> : <SystemUpdateIcon />}
        </IconButton>
      </Tooltip>
    )
  )
}

export default UpdateApp
