import TableCell from '@mui/material/TableCell'
import styled from 'styled-components'

const CellHeader = styled(TableCell)`
  padding: 0px;
  padding-left: 20px;
  padding-right: 10px;
  background-color: #e5ecf3;
  border-right: ${({ align = 'inherit' }) =>
    align === 'center' ? '1px solid #cbcbcb' : ''};
`
CellHeader.displayName = 'CellHeader'
export default CellHeader
