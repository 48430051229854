import dayjs from 'dayjs'
import capitalize from 'lodash/capitalize'
import getDestination from '../../adapters/destination/getDestination'
import dualDate from '../../adapters/days/dualDate'
import calculeTimeDiscount from '../../adapters/days/calculeTimeDiscount'
import getFullDate from '../../adapters/days/getFullDate'
import capitalizeName from '../../utils/capitalizeName'

const normalizeDeliveryEvent = ({
  order_id,
  sales_channel,
  tracking_number,
  destination,
  items,
  status,
  shipping,
  event_created_at,
  customer,
  document_type,
  document_code,
  _id,
  id_type,
  order_created_at,
  ...props
}) => {
  return {
    ...props,
    id: _id,
    idType: capitalize(id_type).replaceAll('-', ' '),
    orderId: order_id,
    salesChannel: capitalize(sales_channel),
    trackingId: tracking_number,
    products: items.map(
      ({
        item_sku,
        item_name,
        item_quantity,
        item_agreed_date,
        item_brand_name,
        item_seller_name,
        item_image,
        item_image_feature,
        ...item
      }) => ({
        ...item,
        name: item_name,
        sku: item_sku,
        quantity: item_quantity,
        date: dualDate(item_agreed_date),
        brand: item_brand_name,
        seller: item_seller_name,
        image: item_image || item_image_feature,
        timeElapsed: calculeTimeDiscount(item_agreed_date)
      })
    ),
    dateOfPurchase: getFullDate(order_created_at),
    timeElapsed: calculeTimeDiscount(items[0]?.item_agreed_date),
    engagedDay: String(dualDate(items[0]?.item_agreed_date)),
    eventCreateAt: event_created_at,
    statusCode: status?.standard_status_code,
    transport: {
      routeId: shipping?.route_id || '',
      company: shipping?.shipping_company || '',
      driver: shipping?.driver_name || '',
      rut: shipping?.driver_rut || ''
    },
    status,
    statusDate: dayjs(status?.status_date).toISOString(),
    destination: destination ? getDestination(destination) : '',
    customer,
    document_type,
    document_code,
    clientName: capitalizeName(customer?.customer_name)
  }
}
export default normalizeDeliveryEvent
