import React from 'react'
import styled from 'styled-components'
import logo from '../../../assets/logo.svg'

const Img = styled('img')`
  border: 1px solid #c1c1c1;
  width: 50px;
  height: 50px;
  @media (max-width: 1100px) {
    margin-top: 5px;
    width: 45px;
    height: 45px;
  }
  @media (max-width: 700px) {
    width: 40px;
    height: 40px;
  }
`

const Logo = () => {
  return <Img src={logo} alt="logo" />
}

export default Logo
