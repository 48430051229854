import { decodeJwt } from '../secrets/decodeJwt'

export const fetchDecodedTracking = async (url, options) => {
  const response = await fetch(url, options)
  const data = await response.text()
  return {
    ok: response.ok,
    ...decodeJwt(process.env.REACT_APP_TRACKING_KEY_H512_PEM)(data)
  }
}

export const fetchDecodeOrder = async (url, options) => {
  const response = await fetch(url, options)
  const data = await response.text()
  return decodeJwt(process.env.REACT_APP_ORDER_KEY_H512_PEM)(data)
}
