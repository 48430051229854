const destinationMapper = new Map([
  ['Aysén', 'Región de Aysén'],
  ['Santiago', 'Región Metropolitana'],
  ['Antofagasta', 'Región de Antofagasta'],
  ['Arica and Parinacota', 'Región de Arica y Parinacota'],
  ['Atacama', 'Región de Atacama'],
  ['Coquimbo', 'Región de Coquimbo'],
  ['Magallanes', 'Región de Magallanes'],
  ['Tarapacá', 'Región de Tarapacá'],
  ['Valparaíso', 'Región de Valparaíso'],
  ['Araucanía', 'Región de La Araucanía'],
  ['Los Lagos', 'Región de Los Lagos'],
  ['Los Ríos', 'Región de Los Ríos'],
  ['Biobío', 'Región de Biobío'],
  ["O'Higgins", "Región de O'Higgins"],
  ['Maule', 'Región del Maulen'],
  ['Ñuble', 'Región de Ñuble']
])

export default destinationMapper
