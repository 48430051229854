import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchOrders from './fetchOrders'

export const orderAdapter = createEntityAdapter()

export const orderSlice = createSlice({
  name: 'orders_list',
  initialState: orderAdapter.getInitialState({
    pagination: {
      currentPagination: 0,
      perPagination: 25,
      paginationTotalCount: 0
    },
    status: 'idle',
    id: [],
    entities: {}
  }),
  reducers: {
    updateOrders: orderAdapter.setAll
  },
  extraReducers: builder => {
    builder.addCase(fetchOrders.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrders.rejected, (state, { meta }) => {
      state.status = meta.aborted ? 'loading' : 'error'
    })
    builder.addCase(fetchOrders.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.pagination = payload.pagination
      state.orders = payload.orders
      orderAdapter.setAll(state, payload.orders)
    })
  }
})

const reducer = orderSlice.reducer
export default reducer
