import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import reducers from './reducers'

export const store = configureStore({
  reducer: reducers,
  // Add the generated reducer as a specific top-level slice

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware => getDefaultMiddleware()
})

setupListeners(store.dispatch)

if (window.Cypress) {
  window.store = store
}
