import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { onIdTokenChanged } from 'firebase/auth'

import persistentStore from '../data/persistentStore'
import auth from '../data/firebase/auth'
import { store } from '../domain/store/store'
import ThemeProvider from './theme/ThemeProvider'
import Routes from './routes/Routes'
import ProvideWorker from './pwa/ProvideWorker'
import useServiceWorker from './pwa/useServiceWorker'

import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from './auth/AuthContext'

const App = () => {
  React.useEffect(() => {
    const unsubcribe = onIdTokenChanged(
      auth,
      token => token && persistentStore.setToken(token.accessToken)
    )
    return unsubcribe
  }, [])

  const worker = useServiceWorker()

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ProvideWorker value={worker}>
        <Provider store={store}>
          <ThemeProvider>
            <AuthProvider>
              <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
                <Routes />
              </BrowserRouter>
            </AuthProvider>
          </ThemeProvider>
        </Provider>
      </ProvideWorker>
    </>
  )
}

export default App
