import styled from 'styled-components'

const Center = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0;
  margin-top: 5%;
`
Center.displayName = 'Center'
export default Center
