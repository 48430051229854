import isString from 'lodash/isString'

const capitalizeName = title => {
  const excludeWords = [
    'de',
    'del',
    'y',
    'o',
    'a',
    'en',
    'el',
    'la',
    'los',
    'las',
    'un',
    'una',
    'unos',
    'unas',
    'con'
  ]
  return isString(title)
    ? title
        ?.toLowerCase()
        ?.split(' ')
        ?.map(word =>
          excludeWords?.includes(word)
            ? word
            : word?.charAt(0)?.toUpperCase() + word?.slice(1)
        )
        ?.join(' ')
    : ''
}

export default capitalizeName
