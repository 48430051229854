import styled from 'styled-components'
import BaseCell from '../BaseCell'

const Cell = styled(BaseCell)`
  width: 124px;
  border-right: ${({ align = 'inherit' }) =>
    align === 'center' ? '1px solid #cbcbcb' : ''};
  &:hover {
    background-color: ${({ align = 'inherit' }) =>
      align === 'center' ? '#dddddd' : ''};
  }
  padding-right: 10px;
  padding-left: 20px;
`
Cell.displayName = 'Cell'
export default Cell
