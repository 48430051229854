import sumBy from 'lodash/sumBy'
import capitalize from 'lodash/capitalize'
import xorBy from 'lodash/xorBy'

import dualDate from '../../adapters/days/dualDate'
import getDestination from '../../adapters/destination/getDestination'
import destinationMapper from '../../mappers/destinationMapper'
import calculeTimeDiscount from '../../adapters/days/calculeTimeDiscount'
import getFullDate from '../../adapters/days/getFullDate'
import capitalizeName from '../../utils/capitalizeName'
import getshippingTypeCode from '../../adapters/shipping/getShippinTypeCode'

const createRowWithOutDelivery = ({
  _id,
  order_id,
  sales_channel,
  destination,
  items,
  customer,
  order_created_at,
  payment_status,
  document_type,
  document_code,
  id_type,
  shipping_type,
  itemsOrder,
  store_name,
  ...order
}) => {
  return {
    ...order,
    id: _id,
    orderId: order_id,
    clientName: capitalizeName(customer?.customer_name),
    customer,
    document_type,
    document_code,
    idType: capitalize(id_type).replaceAll('-', ' '),
    dateOfPurchase: getFullDate(order_created_at),
    shippingType: capitalizeName(shipping_type?.split(' - ')[0]),
    shippingTypeCode: getshippingTypeCode(shipping_type),
    timeElapsed: calculeTimeDiscount(items[0]?.item_agreed_date),
    engagedDay: dualDate(items[0]?.item_agreed_date),
    committedDelivery: undefined,
    conditionOffice: payment_status === 'paid' ? 0 : 9,
    transportCompany: undefined,
    numbeOfpackages: sumBy(items, 'item_quantity'),
    salesChannel: sales_channel,
    seller:
      itemsOrder &&
      itemsOrder.length > 0 &&
      itemsOrder[0]?.item_seller_name
        ? itemsOrder[0]?.item_seller_name
        : 'Kitchen Center',
    destination: destinationMapper.get(destination?.destination_region),
    orderCreatedAt: order_created_at,
    storeName: store_name,
    trackingId: 'no-asignado',
    products: items?.map(
      ({
        item_sku,
        item_name,
        item_quantity,
        item_agreed_date,
        item_brand_name,
        item_seller_name,
        item_image,
        ...item
      }) => ({
        ...item,
        name: item_name,
        sku: item_sku,
        quantity: item_quantity,
        date: dualDate(item_agreed_date),
        brand: item_brand_name,
        seller: item_seller_name,
        image: item_image,
        timeElapsed: calculeTimeDiscount(item_agreed_date)
      })
    ),
    destinationAll: destination ? getDestination(destination) : {}
  }
}

const createRowWithDelivery = ({
  itemsOrder,
  _id,
  order_id,
  sales_channel,
  destination,
  items,
  status,
  id_type,
  shipping,
  tracking_number,
  customer,
  document_type,
  document_code,
  shipping_type,
  order_created_at,
  store_name,
  ...delivery
}) => {
  return {
    ...delivery,
    id: _id,
    idType: capitalize(id_type).replaceAll('-', ' '),
    orderId: order_id,
    clientName: capitalizeName(customer?.customer_name),
    customer,
    document_type,
    document_code,
    shippingType: capitalizeName(shipping_type?.split(' - ')[0]),
    shippingTypeCode: getshippingTypeCode(shipping_type),
    dateOfPurchase: getFullDate(order_created_at) || '',
    committedDelivery: dualDate(items[0]?.item_agreed_date),
    timeElapsed: calculeTimeDiscount(items[0]?.item_agreed_date),
    engagedDay: dualDate(items[0]?.item_agreed_date),
    conditionOffice: status?.standard_status_code,
    transportCompany: shipping?.shipping_company,
    numbeOfpackages: sumBy(items, 'item_quantity'),
    seller:
      itemsOrder?.filter(item => item.item_sku === items[0].item_sku)[0]
        ?.item_seller_name || 'Kitchen Center',
    salesChannel: sales_channel,
    destination: destinationMapper.get(destination?.destination_region),
    orderCreatedAt: order_created_at,
    trackingId: tracking_number,
    status,
    storeName: store_name,
    destinationAll: getDestination(destination)
  }
}

const normalizeItemQuantity = item => ({
  ...item,
  item_quantity: Number(item?.item_quantity)
})

const createTableDeliveryList = data => {
  const retData = Array(data)
    .flat(1)
    .map(({ deliveries, items: itemsOrder, ...order }) => {
      const itemsDeliveries = [...deliveries]
        .map(({ items }) => items.map(normalizeItemQuantity))
        .flat(2)
      const io = itemsOrder && itemsOrder.length > 0 ? itemsOrder : []
      const itemsWithoutDelivieries =
        io.length > 0
          ? xorBy(
              [...io].map(normalizeItemQuantity),
              itemsDeliveries,
              'item_sku'
            )
          : []

      if (deliveries?.length === 0) {
        return createRowWithOutDelivery({
          ...order,
          items:
            itemsWithoutDelivieries.length !== 0
              ? itemsWithoutDelivieries
              : [...itemsOrder],
          itemsOrder
        })
      }
      const table = deliveries
        .flat(2)
        .map(
          ({
            order_id,
            sales_channel,
            destination,
            items,
            status,
            id_type,
            shipping,
            tracking_number,
            ...delivery
          }) =>
            createRowWithDelivery({
              ...order,
              ...delivery,
              itemsOrder,
              order_id,
              sales_channel,
              destination:
                destination.destination_region !== null
                  ? destination
                  : order.destination,
              items,
              status,
              id_type,
              shipping,
              tracking_number
            })
        )
      return [
        ...table,
        itemsWithoutDelivieries.length !== 0
          ? createRowWithOutDelivery({
              ...order,
              items: itemsWithoutDelivieries
            })
          : []
      ]
    })
    .flat(2)
    .map((t, index) => ({ ...t, id: `${t.id}-${index}` }))
  return retData
}
export default createTableDeliveryList
