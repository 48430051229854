import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { addQuery } from '../../../../domain/features/searchTrackingApi/searchSlice'
import { getSearch } from '../../../../domain/features/searchTrackingApi/getSearch'
import fetchSearch from '../../../../domain/features/searchTrackingApi/fetchSearch'

import SearchInput from '../../molecules/SearchInput'

const SearchTracking = () => {
  const [searchParams] = useSearchParams()
  const search = useMemo(() => searchParams.get('q') || '', [searchParams])
  const { status } = useSelector(getSearch)
  const [query, setQuery] = useState(search)

  const handleChange = useCallback(event => {
    event.preventDefault()
    setQuery(event.target.value)
  }, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(addQuery(query)),
        dispatch(fetchSearch({ search: query }))
      ])
      navigate({ pathname: '/', search: `q=${query}` })
    } catch (error) {
      return
    }
  }, [query, dispatch, navigate])

  return (
    <SearchInput
      data-testid="SearchInput"
      id="search"
      name="search"
      value={query}
      isloading={status === 'loading'}
      error={status === 'error'}
      onChange={handleChange}
      onClick={handleSubmit}
    />
  )
}

export default SearchTracking
