import dualDate from '../../adapters/days/dualDate'
import simpleDate from '../../adapters/days/simpleDate'
import getFullDate from '../../adapters/days/getFullDate'
import getDestination from '../../adapters/destination/getDestination'
import calculeTimeDiscount from '../../adapters/days/calculeTimeDiscount'
import capitalizeName from '../../utils/capitalizeName'

const formatDate = date => {
  if (!date || date.length === 0) {
    return null
  }
  var date1 = date.split('/')
  var newDate = date1[1] + '/' + date1[0] + '/' + date1[2]

  var retDate = new Date(newDate)
  return retDate
}

const formatSeller = seller => seller.replaceAll('-', ' ')

const addArray = array => {
  const result = (array || []).reduce(
    (acumulador, numero) => acumulador + numero,
    0
  )
  return result
}
const normalizeOrder = ({
  customer,
  deliveries,
  order_created_at,
  order_id,
  items,
  sales_channel,
  destination
}) => {
  const subrows = deliveries.map((del, index) => ({
    ...del,
    order_id,
    clientName: capitalizeName(customer?.customer_name),
    customer_name: capitalizeName(customer?.customer_name),
    order_created_at:
      getFullDate(order_created_at) ||
      getFullDate(del?.delivery_created_at),
    item_agreed_date:
      del?.item_agreed_date ?? dualDate(del.items[0].item_agreed_date),
    engagedDay: String(dualDate(items[0]?.item_agreed_date)),
    sendStatus: del?.status?.standard_status_code,
    transport: del?.shipping?.shipping_company,
    quantity:
      addArray((del?.items || [])?.map(item => item.item_quantity)) || 0,
    sellChannel: sales_channel,
    seller: formatSeller(
      items.find(item => del.items[0].item_id === item.item_id)
        ?.item_seller || 'Kitchen Center'
    ),
    destiny: destination?.destination_region,
    id: `row-${order_id}-${index}`,
    trackingId: del?.tracking_number,

    customer,
    destinationAll: getDestination(destination),
    sales_channel,
    products: items?.map(
      ({
        item_sku,
        item_name,
        item_quantity,
        item_agreed_date,
        item_brand_name,
        item_seller_name,
        item_image,
        item_featured_image
      }) => ({
        name: item_name,
        sku: item_sku,
        quantity: item_quantity,
        date: dualDate(item_agreed_date),
        brand: item_brand_name,
        seller: item_seller_name,
        image: item_featured_image || item_image,
        timeElapsed: calculeTimeDiscount(item_agreed_date)
      })
    )
  }))

  let dates = subrows.map(v => v.item_agreed_date.split(' - ')).flat(1)
  dates = dates.filter(v => v !== '')
  dates = dates.map(date => formatDate(date))
  const item_agreed_date = Math.min(...dates)
  return {
    quantity:
      addArray((items || [])?.map(item => item.item_quantity)) || 0,
    destiny: subrows.every(v => v?.destiny === subrows[0]?.destiny)
      ? subrows[0]?.destiny
      : 'Varios',
    seller: subrows.every(
      v => v.seller.toLowerCase() === subrows[0]?.seller.toLowerCase()
    )
      ? formatSeller(subrows[0]?.seller)
      : 'Varios',
    sellChannel: subrows.every(
      v =>
        v.sellChannel?.toLowerCase() ===
        subrows[0]?.sellChannel?.toLowerCase()
    )
      ? subrows[0]?.sellChannel
      : 'Varios',
    transport: subrows.every(v => v?.transport === subrows[0]?.transport)
      ? subrows[0]?.transport
      : 'Varios',
    sendStatus: Math.min(...subrows.map(v => v?.sendStatus)),
    item_agreed_date:
      item_agreed_date !== Infinity ? simpleDate(item_agreed_date) : '',
    order_id,
    order_created_at: order_created_at
      ? getFullDate(order_created_at)
      : '',
    customer_name: customer.customer_name,
    items,
    to: {
      pathname: '/pedidos'
    },
    subrows,
    id: new Date().getTime().toString()
  }
}

export default normalizeOrder
