import React from 'react'
import { useSelector } from 'react-redux'
import { getSearch } from '../../../../domain/features/searchTrackingApi/getSearch'

import ErrorSearch from '../../molecules/SearchMessages/ErrorSearch/ErrorSearch'
import InitalSearch from '../../molecules/SearchMessages/InitalSearch'
import TableTemplateTracking from '../../template/TableTemplateTracking'

const useCasesHome = ({ query }) =>
  new Map([
    ['idle', <InitalSearch key="search-idle" />],
    ['error', <ErrorSearch searchText={query} key="search-error" />],
    ['finished', <TableTemplateTracking key="search-table" />]
  ])

const Home = () => {
  const { status, query } = useSelector(getSearch)

  return <div>{useCasesHome({ query }).get(status) || null}</div>
}

export default Home
