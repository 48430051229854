import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import TextBody from '../../../atoms/Typography/TextBody'
import Li from '../../../atoms/Typography/Li'
import Title from '../../../atoms/Typography/Title'
import searchIcon from '../../../../assets/icon/search.svg'
import sombraIcon from '../../../../assets/icon/sombra.svg'
import Center from '../../../atoms/Center/Center'
import ButtonTable from '../../../atoms/ButtonTable'

const CenterLayout = styled(Center)`
  @media (max-width: 1100px) {
    margin-left: 2%;
    margin-right: 2;
  }
`
const LiStyled = styled(Li)`
  @media (max-width: 1100px) {
    font-size: 16px;
  }
`

const Abstract = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 50px;
`
const SubtitleContainer = styled('div')`
  margin-top: 36px;
  margin-bottom: 42px;
  @media (max-width: 1100px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`
const Img = styled('img')`
  transition: all 0.3s ease;
  :hover {
    scale: 1.1;
  }
  @media (max-width: 1100px) {
    scale: 0.7;
  }
`
const ImgSombra = styled('img')`
  @media (max-width: 1100px) {
    scale: 0.7;
  }
`
const Shade = styled('div')`
  margin-top: 16px;
  margin-bottom: 24px;
  @media (max-width: 1100px) {
    margin-bottom: 2%px;
  }
`

const InitalSearch = () => {
  const navigate = useNavigate()

  return (
    <CenterLayout data-testid="InitalSearch__Container--center">
      <Img alt="search" src={searchIcon} />
      <Shade>
        <ImgSombra alt="sombra" src={sombraIcon} />
      </Shade>
      <Title>¡Bienvenido!</Title>
      <SubtitleContainer>
        <TextBody>
          Consulta el estado de un pedido de Kitchen Center. Puedes buscar
          por:
        </TextBody>
      </SubtitleContainer>
      <Abstract>
        <LiStyled>Guía de despacho o identificador Envíame.</LiStyled>
        <LiStyled>
          Datos del cliente: nombre y apellido, mail o RUT con puntos y
          guión (ej: 16.467.850-9).
        </LiStyled>
        <LiStyled>
          Número de pedido Shopify, escrito con un # antes (ej: #330559).
        </LiStyled>
        <LiStyled>
          Otros números de pedidos: boleta/factura, nota de venta,
          solicitud de traslado.
        </LiStyled>
        <LiStyled>
          RUT de SAP para pedidos que no son de Ecommerce (ej: 188188188C).
        </LiStyled>
      </Abstract>

      <ButtonTable
        onClick={() => navigate('/pedidos')}
        type="button"
        label="Ver pedidos"
      />
    </CenterLayout>
  )
}

export default InitalSearch
