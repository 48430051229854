import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
`

export default Text
