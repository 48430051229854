import { createSelector } from '@reduxjs/toolkit'
import { deliveryEventAdapter } from './deliveryEventSlice'

const getStateDeliveryEvent = state => state.deliveryEvent

export const getDeliveryEvent = createSelector(
  [getStateDeliveryEvent],
  deliveryEvent => deliveryEvent
)

const deliveryEventSelector = deliveryEventAdapter.getSelectors(
  getStateDeliveryEvent
)
export const resultDeliveryEvent = deliveryEventSelector.selectAll
