function getFirstImageByColor(product, color) {
  const selectedOption = product?.selectedOptions?.find(
    option => option?.name === 'color' && option?.value === color
  )
  if (!selectedOption) return null

  const mediaImage = product.media.edges.find(edge =>
    edge?.node?.alt?.includes(`#${color}`)
  )
  return Object.freeze({
    url: mediaImage ? mediaImage.node?.preview?.image?.url : null,
    alt: mediaImage ? mediaImage.node?.preview?.image?.altText : null
  })
}

const normalizeProducts = products => {
  const newProducts = products?.edges?.map(({ node }) => {
    const sellerTag = node.product.tags.find(tag => tag.includes('seller'))
    const seller = sellerTag?.split('_')[1]

    const brandTag = node.product.tags.find(tag => tag.includes('brand'))
    const brand = brandTag?.split('_')[1]

    const color = node.selectedOptions.find(
      option => option?.name === 'color'
    )?.value
    const mediaImage = getFirstImageByColor(node, color)

    const ownSkuNode = node?.metafields?.edges?.find(
      edge => edge?.node?.key === 'sku_propio'
    )
    const ownSku = ownSkuNode ? ownSkuNode.node.value : null

    return {
      ...node,
      compareAtPrice: node?.compareAtPrice,
      price: node?.price,
      tags: node?.product?.tags,
      category: node?.product.productType,
      id: node?.id,
      idProduct: node?.product?.id,
      color: color ?? node?.title,
      onlineStorePreviewUrl: node?.product?.onlineStorePreviewUrl,
      onlineStoreUrl: node?.product?.onlineStoreUrl,
      image: mediaImage
        ? { ...mediaImage }
        : { ...node?.product?.featuredImage },
      featuredImageUrl: node?.product?.featuredImage?.url,
      brand: brand ?? node?.brand ?? node?.product?.vendor,
      seller: seller ?? node?.seller,
      sku: node?.sku,
      ownSku
    }
  })
  return newProducts
}
export default normalizeProducts
