/* eslint-disable linebreak-style */
import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, IconButton } from '@mui/material'
import {
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@mui/icons-material'
import styled from 'styled-components'
import Input from '../../atoms/Input'

const TextFieldStyled = styled(Input)`
  color: #212121 !important;

  .MuiInput-input {
    color: black;
    padding-bottom: 7px;
  }

  .MuiFormLabel-colorPrimary {
    color: #828487;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: left;
  }

  .MuiButton-textPrimary {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.04em;
    padding: 0;
    color: black;
    text-decoration: underline;
  }
`

const InputPassword = ({
  showError = false,
  showPassword = false,
  errors,
  label,
  handleClickShowPassword = () => {},
  handleMouseDownPassword = () => {},
  onChange,
  ...props
}) => {
  return (
    <TextFieldStyled
      fullWidth
      error={!!errors?.password || showError}
      id="standard-adornment-password"
      type={showPassword ? 'text' : 'password'}
      variant="standard"
      label={label}
      autoComplete="false"
      onChange={onChange}
      helperText={errors?.password ? errors?.password.message : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <VisibilityOffOutlined />
              ) : (
                <VisibilityOutlined />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}

InputPassword.propTypes = {
  showError: PropTypes.bool,
  showPassword: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  handleClickShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  onChange: PropTypes.func
}

export default InputPassword
