/* eslint-disable security/detect-object-injection */
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'

import normalizeOrder from './normalizeOrder'
import subOrderService from '../../../data/services/subOrderService'
import dateApiFormat from '../../adapters/days/dateApiFormat'

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )

const fetchOrders = createAsyncThunk(
  'orders/filter',
  async (
    { startDate, endDate, orderId, skip, limit, sort },
    { signal, rejectWithValue }
  ) => {
    if (limit === -1 || limit === '-1') {
      limit = 0
    }

    const data = await subOrderService.orderFilter({
      startDate: startDate ? dateApiFormat(startDate) : undefined,
      endDate: endDate ? dateApiFormat(endDate) : undefined,
      orderId,
      skip: limit < 0 ? undefined : skip,
      limit: limit < 0 ? undefined : limit,
      sort,
      signal
    })

    const dta = data.data.map(order => {
      const deliveries = order.deliveries
      const itemsD = deliveries.map(delivery => delivery.items).flat(1)
      const itemsNew = order.items.filter(element => {
        return (
          itemsD.find(itemD => {
            return (
              itemD.item_id === element.item_id ||
              itemD.item_sku === element.item_sku
            )
          }) === undefined
        )
      })
      if (itemsNew.length > 0) {
        const itemsAdd = groupBy(itemsNew, 'item_seller')
        order.deliveries = [
          ...deliveries,
          ...Object.keys(itemsAdd).map(r => {
            return {
              status: { standard_status_code: 0 },
              items: itemsAdd[r],
              sales_channel: 'E-commerce',
              item_agreed_date: ''
            }
          })
        ]
      }
      return order
    })
    const orders = pipe(map(normalizeOrder))(dta)
    if ([...orders].length === 0) {
      return rejectWithValue('Sin contenido')
    }

    return {
      orders,
      pagination: data.pagination
    }
  }
)
export default fetchOrders
