import persistentStore from '../persistentStore'

const productAdminServices = (baseUrl, endpoint) => ({
  getProductBySku: async ({ listSku, signal = undefined }) => {
    const response = fetch(`${baseUrl}/${endpoint}/sku`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      },
      body: JSON.stringify({
        listSku
      }),
      signal
    })

    return response
  }
})

export default productAdminServices(
  process.env.REACT_APP_API_PRODUCT_PATH,
  'product'
)
