import React from 'react'
import styled from 'styled-components'

import Title from './Title'

const TitleNavBar = styled(props => <Title varinat="h1" {...props} />)`
  margin: 0px;
  padding: 0px;
  font-size: 20px;
  line-height: 24px;
  font-style: light;
  font-weight: normal;
  margin-left: 10px;
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.palette.info.main};
  @media (max-width: 1100px) {
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 700px) {
    margin-top: 5px;
    font-size: 16px;
    line-height: 16px;
  }
`

export const SubTitleNavbar = styled('h2')`
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  line-height: 16px;
  font-style: light;
  font-weight: normal;
  margin-left: 10px;
  color: #c1c1c1;

  @media (max-width: 1100px) {
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: 700px) {
    font-size: 11px;
    line-height: 12x;
  }
`

export default TitleNavBar
