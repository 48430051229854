const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
        return null // Devuelve un valor para cumplir con la regla promise/always-return
      })
      .catch(err => {
        console.error('Error importing web-vitals:', err) // Manejo de errores
      })
  }
}

export default reportWebVitals
