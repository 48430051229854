import React from 'react'
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop'
import InstallMobileIcon from '@mui/icons-material/InstallMobile'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

import useInstallApp from './useInstallApp'
import Tooltip from '../components/atoms/ToolTip/ToolTip'
import useWorker from './useWorker'

const InstallApp = () => {
  const [isSupportPWA, onPrompt] = useInstallApp()
  const { isUpdateWorker } = useWorker()
  const match = useMediaQuery('(min-width:1100px)')

  if (isUpdateWorker) {
    return null
  }

  return (
    isSupportPWA && (
      <Tooltip
        role="tooltip"
        title="Instala Tracking de Kitchen Center en tú computador o celular y disfruta el mejor rendimiento ⚡."
        placement="bottom-start"
        followCursor
      >
        <IconButton color="info" onClick={onPrompt}>
          {match ? <InstallDesktopIcon /> : <InstallMobileIcon />}
        </IconButton>
      </Tooltip>
    )
  )
}

export default InstallApp
