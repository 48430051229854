import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { normalizeAuth } from '../../domain/adapters/validateEmail/normalizeUser'
import auth from '../../data/firebase/auth'
import persistentStore from '../../data/persistentStore'
import AuthValid from './AuthValid'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const updateCurrentUser = useCallback(
    newUser => {
      const userNewAuth = normalizeAuth(newUser)

      if (newUser && !isEqual(userNewAuth, currentUser)) {
        persistentStore.setUser(userNewAuth)
        setCurrentUser(userNewAuth)
      }
    },
    [currentUser]
  )

  useEffect(() => {
    const storedUser = persistentStore.getUser()
    if (storedUser) {
      updateCurrentUser(storedUser)
    }

    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        const tokenResult = await user.getIdTokenResult()

        persistentStore.setToken(tokenResult?.token)

        updateCurrentUser({
          uid: user.uid,
          photoURL: user.photoURL,
          ...tokenResult.claims
        })
      }

      setLoading(false)
    })

    return unsubscribe
  }, [updateCurrentUser])

  const memoizedValue = useMemo(
    () => ({
      currentUser
    }),
    [currentUser]
  )

  return (
    <AuthContext.Provider value={{ ...memoizedValue }}>
      {!loading ? children : <AuthValid />}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = { children: PropTypes.node }
AuthProvider.defaultProps = { children: null }
