import simpleDate from './simpleDate'

const dualDate = dates => {
  switch (dates?.length) {
    case 0:
      return ''
    case 1:
      return simpleDate(dates[0])
    case 2:
      return `${simpleDate(dates[0])} - ${simpleDate(dates[1])}`
    default:
      return simpleDate(dates)
  }
}

export default dualDate
