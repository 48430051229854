export const checkPermission = (permissions = []) => {
  if (typeof permissions === 'undefined') return false
  if (permissions === null) return false
  if (!permissions) return false
  if (permissions.length === 0) return false

  const appId = process.env.REACT_APP_APP_ID.toString()

  const hasPermission = permissions?.some(
    permission => permission?.appId?.toString() === appId
  )

  return hasPermission
}
