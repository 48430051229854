import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  signInWithEmailAndPassword,
  indexedDBLocalPersistence
} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import ReportGmailerrorredOutlined from '@mui/icons-material/ReportGmailerrorredOutlined'

import { schema } from '../../../../domain/schemas/loginFormSchema'
import auth from '../../../../data/firebase/auth'

import InputEmailController from '../InputEmailController/InputEmailController'
import InputPasswordController from '../InputPasswordController/InputPasswordController'
import Modal from '../Modal'
import persistentStore from '../../../../data/persistentStore'

const ButtonContainer = styled('div')`
  margin-top: 46px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`

const ButtonStyled = styled(Button)`
  height: 49px;
  border-radius: 5px;
`

const ErrorText = styled('p')`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0;
  color: #e72020;
`

const ErrorIcon = styled(ReportGmailerrorredOutlined)`
  color: #e72020;
  margin-right: 10px;
`

const ErrorContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const Form = styled('form')`
  display: flex;
  flex-direction: column;
`

const errorModal = [
  'auth/insufficient-permission',
  'auth/invalid-disabled-field',
  'auth/invalid-credential',
  'auth/user-disabled'
]

const LoginForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState({
    showError: false,
    error: ''
  })
  const [values, setValues] = useState({
    showPassword: false
  })
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => setOpen(true), [])

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: '', password: '' },
    mode: 'firstError'
  })

  const handleClose = useCallback(() => {
    reset({ email: '', password: '' })
    setOpen(false)
  }, [reset])

  const navigate = useNavigate()

  const sessionHandler = useCallback(
    async ({ email, password }) => {
      setLoading(true)

      try {
        await auth.setPersistence(indexedDBLocalPersistence)
        const { user } = await signInWithEmailAndPassword(
          auth,
          email,
          password
        )

        const userClaims = await user.getIdTokenResult()
        persistentStore.setToken(userClaims.token)
        persistentStore.setUser({ ...user, ...userClaims.claims })

        navigate('/', { replace: true })
      } catch (error) {
        persistentStore.deleteAuth()

        if (errorModal.includes(error.code)) {
          return handleOpen()
        }

        setError({
          error:
            'El correo o contraseña son incorrectos. Intenta de nuevo.',
          showError: !values.showError
        })
      }
      setLoading(false)
    },
    [navigate, handleOpen, values.showError]
  )

  const handleClickShowPassword = () =>
    setValues({
      ...values,
      showPassword: !values.showPassword
    })

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <>
      <Form onSubmit={handleSubmit(sessionHandler)}>
        <InputEmailController
          label="Ingresa tu correo electrónico"
          errors={errors}
          showError={error.showError}
          control={control}
        />
        <InputPasswordController
          label="Ingresa tu contraseña"
          showError={error.showError}
          control={control}
          errors={errors}
          showPassword={values.showPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />
        <ButtonContainer>
          <ButtonStyled
            type="submit"
            fullWidth
            disabled={isLoading || isSubmitting}
            onClick={handleSubmit(sessionHandler)}
            variant="contained"
            endIcon={
              isLoading || isSubmitting ? (
                <CircularProgress size="1.2rem" />
              ) : null
            }
          >
            Ingresar
          </ButtonStyled>
        </ButtonContainer>
      </Form>

      <Collapse in={error.showError}>
        <ErrorContainer>
          <ErrorIcon fontSize="large"></ErrorIcon>
          <ErrorText>{error.error} </ErrorText>
        </ErrorContainer>
      </Collapse>
      <Modal open={open} handleClose={handleClose} />
    </>
  )
}

export default LoginForm
