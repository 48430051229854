import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchDeliveryEvent from './fetchDeliveryEvent'

export const deliveryEventAdapter = createEntityAdapter()

export const deliveryEventSlice = createSlice({
  name: 'deliveryEvent',
  initialState: deliveryEventAdapter.getInitialState({
    status: 'idle',
    query: '',
    entities: {}
  }),
  reducers: {
    addQuery(state, action) {
      state.query = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchDeliveryEvent.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchDeliveryEvent.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchDeliveryEvent.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      deliveryEventAdapter.setAll(state, payload)
    })
  }
})
export const { addQuery } = deliveryEventSlice.actions
const reducer = deliveryEventSlice.reducer
export default reducer
