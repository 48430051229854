import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import join from 'lodash/fp/join'
import destinationMapper from '../../mappers/destinationMapper'
import capitalizeName from '../../utils/capitalizeName'
import { deleteUndefinedEntries } from '../../utils/deleteUndefinedEntries'

const transformText = (text = '') =>
  pipe(
    text => String(text),
    text => text.trim().trimEnd(),
    text => text?.toLowerCase(),
    capitalizeName
  )(text)

const getDestination = ({
  destination_address1,
  destination_address2,
  destination_county,
  destination_region
}) =>
  pipe(
    deleteUndefinedEntries,
    ({ destination_region, ...rest }) => ({
      ...rest,
      destination_region: destinationMapper.get(destination_region)
    }),
    Object.values,
    map(transformText),
    join(', ')
  )({
    destination_address1,
    destination_address2,
    destination_county,
    destination_region
  })
export default getDestination
