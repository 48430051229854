import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import FetchStores from './fetchStores'

export const storeAdapter = createEntityAdapter()

export const storeSlice = createSlice({
  name: 'stores_search',
  initialState: storeAdapter.getInitialState({
    status: 'idle',
    store: {}
  }),
  reducers: {
    resetStore(state) {
      state.status = 'idle'
      state.store = {}
    }
  },
  extraReducers: builder => {
    builder.addCase(FetchStores.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(FetchStores.rejected, (state, { meta }) => {
      state.status = meta.aborted ? 'loading' : 'error'
    })
    builder.addCase(FetchStores.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.store = payload
    })
  }
})

const reducer = storeSlice.reducer
export const { resetStore } = storeSlice.actions
export default reducer
