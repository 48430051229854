import TableCell from '@mui/material/TableCell'
import styled from 'styled-components'

const BaseCell = styled(TableCell)`
  height: 56px;
  padding: 0px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 140px;
`
BaseCell.displayName = 'BaseCell'
export default BaseCell
