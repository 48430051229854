import React from 'react'
import TextEmpty from '../../Typography/TextEmpty'
import Cell from '../Cell/Cell'

const CellEmpty = () => {
  return (
    <Cell>
      <TextEmpty>No asignado</TextEmpty>
    </Cell>
  )
}

export default CellEmpty
