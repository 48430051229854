/**
 * Normalizes the user authentication data.
 *
 * @param {Object} user - The user object.
 * @param {string} user.displayName - The display name of the user.
 * @param {string} user.email - The email of the user.
 * @param {string} user.photoURL - The photo URL of the user.
 * @param {Array} user.permissions - The permissions array of the user.
 * @param {string} user.uid - The UID of the user.
 * @param {string} [user.name] - The name of the user.
 * @param {string} [user.picture] - The picture URL of the user.
 * @returns {Object} The normalized user object.
 * @returns {string} normalizedUser.email - The email of the user.
 * @returns {string} normalizedUser.photoURL - The photo URL of the user.
 * @returns {string} normalizedUser.role - The role of the user (possible values: 'admin', 'operations', 'viewer').
 * @returns {string} normalizedUser.uid - The UID of the user.
 * @returns {Array} normalizedUser.permissions - The permissions array of the user.
 * @returns {string} normalizedUser.displayName - The display name of the user.
 */
export const normalizeAuth = ({
  displayName,
  email,
  photoURL,
  permissions,
  uid,
  name,
  picture
}) => {
  const customRole = permissions?.find(
    permission =>
      permission?.appId?.toString() ===
      process.env.REACT_APP_APP_ID.toString()
  )?.role

  return Object.freeze({
    email,
    photoURL: photoURL || picture,
    role: customRole ? customRole : 'viewer',
    uid,
    permissions,
    displayName: name || displayName
  })
}
