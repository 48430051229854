import persistentStore from '../persistentStore'

const userSerives = baseUrl => ({
  desactive: async ({ uid, token }) => {
    return await fetch(`${baseUrl}/desactive/${uid}`, {
      method: 'PATCH',
      headers: {
        authorization: token || persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      },
      body: JSON.stringify({ isActive: false })
    })
  },
  customRole: async ({ uid }) => {
    return await fetch(
      `${process.env.REACT_APP_API_USER_API_PATH}/user/add-custom-role/${uid}`,
      {
        method: 'POST',
        headers: {
          'api-key': process.env.REACT_APP_USER_API_KEY
        }
      }
    )
  }
})

export default userSerives(process.env.REACT_APP_API_USER_PATH)
