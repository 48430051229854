import React, { useMemo } from 'react'
import { node, string } from 'prop-types'
import {
  ThemeProvider as ThemeProviderUI,
  StyledEngineProvider
} from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import CssBaseline from '@mui/material/CssBaseline'

import theme from './theme'
import useThemeDark from './useThemeDark'

const ThemeProvider = ({ children }) => {
  const [modeTheme] = useThemeDark()
  const defaultTheme = useMemo(() => theme(modeTheme), [modeTheme])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProviderUI theme={defaultTheme}>
        <StyledThemeProvider theme={defaultTheme}>
          <CssBaseline />
          {children}
        </StyledThemeProvider>
      </ThemeProviderUI>
    </StyledEngineProvider>
  )
}
ThemeProvider.propTypes = {
  children: node.isRequired,
  mode: string
}
export default ThemeProvider
