import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchOrderListDownload from './fetchOrderListDownload'

export const orderListDownloadAdapter = createEntityAdapter()

export const orderListDownloadSlicer = createSlice({
  name: 'order/download',
  initialState: orderListDownloadAdapter.getInitialState({
    status: 'idle',
    url: {},
    errors: null
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchOrderListDownload.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      fetchOrderListDownload.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.errors = payload
      }
    )
    builder.addCase(
      fetchOrderListDownload.fulfilled,
      (state, { payload }) => {
        state.status = 'finished'
        state.url = payload
      }
    )
  }
})

const reducer = orderListDownloadSlicer.reducer
export default reducer
