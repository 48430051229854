import styled from 'styled-components'

const Li = styled('li')`
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-style: normal;
  font-weight: normal;
`

export default Li
