import { createAsyncThunk } from '@reduxjs/toolkit'
import subOrderService from '../../../data/services/subOrderService'
import dateApiFormat from '../../adapters/days/dateApiFormat'
const fetchOrderListDownload = createAsyncThunk(
  'order/export',
  async ({ startDate, endDate, orderId }, { signal, rejectWithValue }) => {
    try {
      const response = await subOrderService.exportOrderAllData({
        fromDate: startDate ? dateApiFormat(startDate) : undefined,
        toDate: endDate ? dateApiFormat(endDate) : undefined,
        orderId: orderId || undefined,
        signal
      })

      if (!response) {
        return rejectWithValue(
          `Descargar lista pedidos falló. ${response?.message}`
        )
      }

      if (response) {
        const resJson = await response
        const blob = resJson.file

        if (blob) {
          const newBlob = new Blob([new Uint8Array(resJson.file.data)])

          const blobUrl = window.URL.createObjectURL(newBlob)

          const link = document.createElement('a')
          link.href = blobUrl
          link.setAttribute('download', 'pedidos.xlsx')
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(newBlob)
        }

        return resJson
      } else {
        throw new Error('file not found')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchOrderListDownload
