import dayjs from 'dayjs'

import { es } from 'dayjs/locale/es'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(relativeTime)

dayjs.locale(es)

const dualDate = dates => {
  switch (dates?.length) {
    case 1:
      return dates[0]
    case 2:
      return dates[1]
    default:
      return dates
  }
}

const calculeTimeDiscount = futureDate => {
  const limitDate = dualDate(futureDate)

  if (!limitDate) {
    return ''
  }

  const now = dayjs()

  const targetDay = dayjs(limitDate)
  const calculeDiff = targetDay.diff(now)
  const diff = dayjs.duration(calculeDiff)

  const day = diff.days()
  const hour = diff.hours()
  const minute = diff.minutes()
  const totalDates = targetDay.diff(now, 'days')

  return Object.freeze({
    totalDates,
    day,
    hour,
    minute
  })
}

export default calculeTimeDiscount
