import styled from 'styled-components'
import { animated } from '@react-spring/web'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  cursor: wait;
  background-color: #313234;
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 250px;
  height: 250px;
  @media (max-width: 1100px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 700px) {
    width: 120px;
    height: 120px;
  }
`

export const ImageStyled = styled(animated('img'))`
  display: 'block';
  width: 100%;
  height: 100%;
  border: 1px solid #c1c1c1;
  margin-top: 15px;
`

export const EmojiBox = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
  font-size: 60px;
  @media (max-width: 1100px) {
    font-size: 50px;
  }
  @media (max-width: 700px) {
    font-size: 40px;
  }
`

export const MessageBox = styled(animated.h3)`
  color: #313234;
  font-size: 24px;
  line-height: 26px;
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 300px;
  height: 50px;
  color: #ffffff;
`

export const ContainerTitle = styled(animated('div'))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  white-space: nowrap;
`
